import React from 'react';
import { isBefore } from 'date-fns';
import { Stack } from '@nearst/ui';

import { useInsightsDate } from '@services/InsightsDateProvider';
import { useStoredQuery } from '@services/insights';
import TimeSeriesGraph from '@components/Graphs/TimeSeriesGraph';
import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';
import NoData from '@components/Graphs/Placeholders/NoData';
import { sumByDate } from '../../utils';

import styles from './ProductPageLinkingAnalytics.module.scss';

const ProductPageLinkingAnalytics = ({ shops }) => {
	const shopIds = shops.map((shop) => shop.id);
	const { selectedRange, endDate, interval } = useInsightsDate();

	const { data: summary, isLoading: summaryLoading } = useStoredQuery(
		'product-page-linking-summary',
		{ shopIds },
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false,
			refreshInterval: 0
		}
	);

	const { data: timeseries, isLoading: timeseriesLoading } = useStoredQuery(
		'product-page-linking-timeseries',
		{ shopIds, lookback: true },
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false,
			refreshInterval: 0
		}
	);

	const noData = !summaryLoading && !timeseriesLoading && summary[0]?.count === 0;
	const isBeforeDataCollection = isBefore(new Date(endDate), new Date('2024-11-01'));

	const clicks = sumByDate(timeseries?.current || [], interval);
	const clicksLookback = sumByDate(timeseries?.lookback || [], interval);

	return (
		<Stats.Card className={styles.card}>
			{noData ? (
				<>
					<div>
						<Stats.Header>
							<Stats.Title>Customers reaching your website</Stats.Title>
						</Stats.Header>
						<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
					</div>
					<div>
						<NoData text={isBeforeDataCollection ? 'This metric is tracked from 1st November 2024' : 'No data available'} />
					</div>
				</>
			) : (
				<div className={styles.content}>
					<>
						<div>
							<Stats.Header>
								<Stats.Title>Customers reaching your website</Stats.Title>
							</Stats.Header>
							<Stats.Subtitle>{selectedRange}</Stats.Subtitle>

							<Stack space="1rem" className={styles.stats}>
								{summaryLoading ? (
									<>
										<Skeleton height={58} width={'100%'} /> <Skeleton height={58} width={'100%'} />
									</>
								) : (
									<>
										<Stats.Figure>
											{summary[0].count.toLocaleString()}
											<Stats.FigureDescription>Clicks to websites from Local Listings</Stats.FigureDescription>
										</Stats.Figure>
										<Stats.Figure>
											{(summary[0]?.pricesum || 0).toLocaleString('en', {
												style: 'currency',
												currency: shops[0].currency || 'GBP',
												minimumFractionDigits: 0,
												maximumFractionDigits: 0
											})}
											<Stats.FigureDescription>
												Total journey value
												<Stats.Tooltip>The potential revenue associated with website conversions</Stats.Tooltip>
											</Stats.FigureDescription>
										</Stats.Figure>
									</>
								)}
							</Stack>
						</div>
						<div className={styles.graphWrapper}>
							{timeseriesLoading ? (
								<Skeleton height={255} width={400} />
							) : (
								<TimeSeriesGraph
									series={clicks.map((row, index) => ({
										date: row.date,
										value: row.clicks,
										previousDate: clicksLookback[index]?.date,
										previousValue: clicksLookback[index]?.clicks
									}))}
									yAxisLabel={'Clicks'}
									lineColor="#2aca79"
									dateAxis
								/>
							)}
						</div>
					</>
				</div>
			)}
		</Stats.Card>
	);
};

export default ProductPageLinkingAnalytics;
