import React from 'react';
import { Card, Stack, Switcher } from '@nearst/ui';

import { useStoredQuery } from '@services/insights';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { formatPrice } from '@utils/currency';

import Skeleton from '@components/Skeleton';
import * as Stats from '@components/StatsCard';
import ErrorBoundary from '@components/ErrorBoundary';
import TimeSeriesGraph from '@components/Graphs/TimeSeriesGraph';
import Toggle from '@components/Graphs/Toggle';

import styles from './PerformanceOverTime.module.scss';

const PerformanceOverTime = () => {
	const { selectedRange } = useInsightsDate();
	const { data: summary, isLoading: summaryLoading } = useStoredQuery('product-locator-summary');
	const { data: timeseries, isLoading: timeseriesLoading } = useStoredQuery('product-locator-summary-timeseries', { lookback: true });

	return (
		<>
			<Card>
				<Stats.Header>
					<div>
						<Stats.Title>Performance over time</Stats.Title>
						<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
					</div>
				</Stats.Header>
				<>
					<Stack className={styles.container}>
						<ErrorBoundary>
							<Switcher>
								<Stats.Figure>
									{summaryLoading ? (
										<Skeleton height={28} width={120} />
									) : (
										Number(summary[0].new_customers).toLocaleString()
									)}
									<Stats.FigureDescription>New customers</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{summaryLoading ? (
										<Skeleton height={28} width={120} />
									) : (
										Number(summary[0].returning_customers).toLocaleString()
									)}
									<Stats.FigureDescription>Returning customers</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{summaryLoading ? (
										<Skeleton height={28} width={120} />
									) : (
										Number(summary[0].location_searches).toLocaleString()
									)}
									<Stats.FigureDescription>Location searches</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{summaryLoading ? (
										<Skeleton height={28} width={120} />
									) : (
										formatPrice(Number(summary[0].journey_value), summary[0].currency || 'GBP')
									)}
									<Stats.FigureDescription>
										Total journey value
										<Stats.Tooltip>The potential revenue from all local products viewed by shoppers</Stats.Tooltip>
									</Stats.FigureDescription>
								</Stats.Figure>
							</Switcher>
						</ErrorBoundary>
						<ErrorBoundary>
							<Toggle
								initialMeasure={{ value: 'widget_views', label: 'Widget views' }}
								measures={[
									{ value: 'widget_views', label: 'Widget views' },
									{ value: 'directions_clicks', label: 'Directions clicks' },
									{ value: 'location_searches', label: 'Location searches' }
								]}
							>
								{(measure) =>
									timeseriesLoading ? (
										<Skeleton height={275} width={'100%'} />
									) : (
										<div className={styles.graphWrapper}>
											<TimeSeriesGraph
												series={timeseries.current.map((row, index) => ({
													date: row.date,
													value: row[measure.value],
													previousDate: timeseries.lookback?.[index]?.date,
													previousValue: timeseries.lookback?.[index]?.[measure.value]
												}))}
												yAxisLabel={measure.label}
												lineColor="#2aca79"
												dateAxis
											/>
										</div>
									)
								}
							</Toggle>
						</ErrorBoundary>
					</Stack>
				</>
			</Card>
		</>
	);
};

export default PerformanceOverTime;
