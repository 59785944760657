import React from 'react';
import { Link } from 'react-router-dom';

import { Page, Card } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';
import { useChannel, useChannelDefinitions } from '@services/channels';

import DatePicker from '@components/DatePicker';
import UpgradeRequired from '@components/UpgradeRequired';

import EventLocations from './components/EventLocations';
import RealtimeEventsCard from './components/RealtimeEventsCard';
import RecentProductsCard from './components/RecentProductsCard';
import PerformanceOverTime from './components/PerformanceOverTime';
import TopStores from './components/TopStores';

import styles from './ProductLocator.module.scss';
import ProductLocatorPopularProducts from './components/ProductLocatorPopularProducts';

const ProductLocator = () => {
	const { data: channelConfig } = useChannel('product-locator');

	return (
		<>
			<Page.Header>
				<h1>Product Locator</h1>
				<p>Real-time analysis of customers using the NearSt Product Locator widget while browsing your website.</p>
				<DatePicker />
			</Page.Header>
			<Page.Section>
				{!channelConfig && <Disabled />}
				{channelConfig.status === 'pending' && <Pending />}
				{channelConfig.status === 'enabled' && (
					<>
						<div className={styles.split}>
							<RealtimeEventsCard />
							<RecentProductsCard />
						</div>
						<PerformanceOverTime />
						<div className={styles.splitBigSmall}>
							<EventLocations />
							<TopStores />
						</div>
						<ProductLocatorPopularProducts />
					</>
				)}
			</Page.Section>
		</>
	);
};

function Disabled() {
	const { data: shops } = useShop();
	const { data: channelDefinition } = useChannelDefinitions('product-locator');
	return (
		<Card title="Product Locator is not enabled">
			<p>Please enable the Product Locator to start seeing results.</p>
			<UpgradeRequired availableOnPlans={channelDefinition.requirements.plans}>
				<Link className={styles.link} to={`/channels/${shops[0].id}/manage/product-locator`}>
					Enable
				</Link>
			</UpgradeRequired>
		</Card>
	);
}
function Pending() {
	const { data: shops } = useShop();
	return (
		<Card title="Product Locator is in setup">
			<p>Please check back here once setup is complete to start seeing results.</p>
			<Link className={styles.link} to={`/channels/${shops[0].id}/manage/product-locator`}>
				Continue setup
			</Link>
		</Card>
	);
}

export default ProductLocator;
