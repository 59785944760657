import React from 'react';
import { skeleton } from './Skeleton.module.scss';

/**
 * Render a skeleton loader component.
 *
 * @param {Object} props
 * @param {number} [props.width=512] - The width of the skeleton loader
 * @param {number} [props.height=512] - The height of the skeleton loader
 * @param {number} [props.radius=4] - The border radius of the skeleton loader
 * @param {string} [props.className] - Additional classes to apply to the component
 */
const Skeleton = ({ className = '', width = 512, height = 512, radius = 4 }) => {
	return (
		<svg
			className={`${skeleton} ${className}`}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			preserveAspectRatio="none"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>skeleton</title>

			<rect rx={radius}>
				<animate attributeName="opacity" values="0.2;0.8;0.2" dur="2.5s" repeatCount="indefinite" />
			</rect>
		</svg>
	);
};
export default Skeleton;
