import React from 'react';
import useSWR from 'swr';

import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';
import { getFeedQualityStats } from '@services/stock';

const ActiveProductsCount = ({ shop }) => {
	const key = `${shop.id}-google-feed-quality-stats`;
	const { data, isLoading } = useSWR(key, () => getFeedQualityStats(shop.id, 'google'));

	return (
		<Stats.Figure>
			{isLoading ? <Skeleton width={100} height={32} /> : (data?.activeItems || 0).toLocaleString()}
			<Stats.FigureDescription>Active products</Stats.FigureDescription>
		</Stats.Figure>
	);
};

export default ActiveProductsCount;
