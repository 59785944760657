import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

export const getDimensions = (shops, hasStoreVisits) => {
	const dimensions = [
		{
			id: 'impressions',
			label: 'Impressions',
			description: <>You are currently comparing the number of times Local Ads have been shown for local searches.</>
		},
		{
			id: 'clicks',
			label: 'Clicks',
			description: <>You are currently comparing the number of times shoppers clicked on your Local Ads.</>
		},
		{
			id: 'ctr',
			label: 'CTR',
			valueSuffix: '%',
			summaryType: 'avg',
			digits: 2,
			description: (
				<>
					You are currently comparing the click-through rate across locations.
					<br />
					This indicates how often your shoppers clicked on a product after seeing it in their search results.
				</>
			)
		},
		{
			id: 'cpc',
			label: 'CPC',
			summaryType: 'avg',
			valuePrefix: getSymbolFromCurrency(shops[0].billingCurrency),
			digits: 2,
			description: <>You are currently comparing how much of your daily budget you spend per click on your Local Ads.</>
		}
	];
	if (hasStoreVisits) {
		dimensions.push({
			id: 'store_visits',
			label: 'Store visits',
			summaryType: 'sum',
			description: <>You are currently comparing the number of times shoppers visited your store after viewing your Local Ads.</>
		});
	}
	return dimensions;
};
