import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

import { UItils } from '@nearst/ui';

import styles from './LocationsMap.module.scss';

export const Dot = ({ shop }) => {
	const [open, setOpen] = useState(false);
	const location = JSON.parse(shop.geolocation);
	return (
		<Marker longitude={location.lng} latitude={location.lat}>
			<div
				className={UItils.classnames(styles.pointer, styles.store)}
				onMouseEnter={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
			>
				<Icon />
			</div>

			{open && (
				<Popup className={styles.tooltip} longitude={location.lng} latitude={location.lat} anchor="bottom">
					<p>{shop.name}</p>
					<p className={styles.tooltipSubtitle}>{shop.address}</p>
				</Popup>
			)}
		</Marker>
	);
};

export const Icon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
			<g>
				<path
					fillRule="evenodd"
					d="M6 5v1H4.667a1.75 1.75 0 0 0-1.743 1.598l-.826 9.5A1.75 1.75 0 0 0 3.84 19H16.16a1.75 1.75 0 0 0 1.743-1.902l-.826-9.5A1.75 1.75 0 0 0 15.333 6H14V5a4 4 0 0 0-8 0Zm4-2.5A2.5 2.5 0 0 0 7.5 5v1h5V5A2.5 2.5 0 0 0 10 2.5ZM7.5 10a2.5 2.5 0 0 0 5 0V8.75a.75.75 0 0 1 1.5 0V10a4 4 0 0 1-8 0V8.75a.75.75 0 0 1 1.5 0V10Z"
					clipRule="evenodd"
				/>
			</g>
		</svg>
	);
};

export const Circle = ({ className = '', store = false }) => {
	return <div className={UItils.classnames(styles.store, className)}>{store && <Icon />}</div>;
};
