import { getMultipleInsights } from '@services/insights';
import { groupByShop } from '../components/ComparisonCard/lib/dataVisualization';

export const getLocalAdsData = async ({ to, from, shopIds, dimension }) => {
	const query = {
		table: 'local_ads',
		from,
		to,
		id: shopIds,
		columns: ['clicks', 'impressions', 'cpc', 'shop_id', 'date'],
		sum: ['clicks', 'impressions'],
		avg: ['cpc']
	};

	const storeVisitsQuery = {
		table: 'local_ads_store_visits',
		from,
		to,
		id: shopIds,
		columns: ['store_visits', 'shop_id', 'date'],
		sum: ['store_visits']
	};

	let results;

	if (dimension === 'store_visits') {
		results = await getMultipleInsights([storeVisitsQuery]);
	} else {
		results = await getMultipleInsights([query]);
	}

	if (dimension === 'ctr') {
		const impressions = groupByShop(results, 'impressions');
		const clicks = groupByShop(results, 'clicks');
		return impressions.map(({ shopId, value }) => ({
			shopId,
			value: ((clicks.find((v) => v.shopId === shopId)?.value || 0) / value) * 100
		}));
	}

	return groupByShop(results, dimension, dimension === 'cpc' ? 'avg' : 'sum');
};

export const checkStoreVisitsData = async (shopIds) => {
	const storeVisitsQuery = {
		table: 'local_ads_store_visits',
		id: shopIds,
		columns: ['store_visits', 'shop_id', 'date'],
		sum: ['store_visits']
	};

	const results = await getMultipleInsights([storeVisitsQuery]);

	return !!results.length;
};
