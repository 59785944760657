import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { register } from '@services/registration';
import AddressForm from '../../Forms/AddressForm';
import Address from '../../Components/Address';
import Autocomplete from '../../Components/Fields/AutoComplete';
import { sendAnalytics } from '../../../../utils/sendAnalytics';
import getQueryParam from '../../../../utils/getQueryParam';
import PageLayout from '../PageLayout';
import { marts } from '../../utils';
import RegistrationBlock from './RegistrationBlock';

const Registration = () => {
	let navigate = useNavigate();
	const location = useLocation();
	const [errorMsg, setErrorMsg] = useState('');
	const partnerKey = getQueryParam(location, 'partner_key') || undefined;
	const signupSource = getQueryParam(location, 'utm_source') || undefined;

	// default values for development to save typing!
	const isTest = (!import.meta.env.PROD && getQueryParam(location, 'test')) || undefined;
	const testData = isTest ? marts : {};

	// keep an eye on the stage so we can show different components
	const [stage, setStage] = useState(isTest ? 'address_complete' : 'autocomplete');

	const defaultValues = {
		partnerKey,
		signupSource,
		...testData
	};

	const methods = useForm({
		defaultValues,
		mode: 'onChange'
	});

	const {
		formState: { errors }
	} = methods;

	const onSubmit = async (data) => {
		if (Object.entries(errors).length) {
			console.error(errors);
			return;
		}
		try {
			await register(data);
			location.search = `?email=${data.contactEmail}`;
			navigate(location);

			sendAnalytics('Registration sign-up completed', 'submit', data?.contactEmail);
		} catch (e) {
			// this only happens if there's a backend error
			// validation fail messaging is taken care of by the form
			console.error(e);
			setErrorMsg('Something went wrong processing your request - please try again.');
		}
	};
	const onError = (errors) => {
		// this scenario only happens if there's a validation fail
		// no need to message - validation errors should pop up
		console.error(errors);
	};

	return (
		<PageLayout>
			<h1>Let's make your products visible online.</h1>

			<FormProvider {...methods}>
				{!stage || stage === 'autocomplete' ? <Autocomplete setStage={setStage} /> : null}
				{stage && (stage === 'edit_address' || stage === 'autocomplete_error') ? <AddressForm setStage={setStage} /> : null}
				{stage && stage === 'address_complete' ? <Address setStage={setStage} /> : null}

				{stage && stage === 'address_complete' && (
					<RegistrationBlock onSubmit={onSubmit} submitErrorMsg={errorMsg} onValidationError={onError} />
				)}
			</FormProvider>
		</PageLayout>
	);
};

export default Registration;
