import React, { useState, Suspense } from 'react';
import useSWR from 'swr';

import { Pill } from '@nearst/ui';
import Skeleton from '@components/Skeleton';

import { getOrganisationStockStatus } from '@services/stock';

import styles from './OrganisationStockStatus.module.scss';
import { ChevronRightIcon, PencilSquareIcon } from '@heroicons/react/20/solid';

import { TemplateModal } from '@nearst/ui';
import Table from '@components/Table';
import { useShop } from '@services/ShopProvider';
import { Link } from 'react-router-dom';

const OrganisationStockStatus = ({ barcode }) => {
	return (
		<Suspense fallback={<Skeleton height={18} width={120} radius={9} />}>
			<OrganisationStockStatusContent barcode={barcode} />
		</Suspense>
	);
};

const OrganisationStockStatusModal = ({ isOpen, close, barcode, data }) => {
	const { data: shops } = useShop();

	return (
		<TemplateModal
			aria-label="Stock status across the organisation"
			isOpen={isOpen}
			onDismiss={close}
			showDismissButton={false}
			title={`In stock in ${data.length} locations`}
			className={styles.modal}
		>
			<Table
				data={data}
				fields={[
					{
						id: 'location',
						title: 'Location',
						formatter: (item) => shops.find((shop) => shop.id === item.shopId)?.name
					},
					{
						title: 'Quantity',
						id: 'quantity',
						sortFn: {
							asc: (a, b) => a.quantity - b.quantity,
							desc: (a, b) => b.quantity - a.quantity
						},
						formatter: (item) => Number(item.quantity).toLocaleString()
					},
					{
						title: 'Price',
						id: 'price',
						sortFn: {
							asc: (a, b) => a.price - b.price,
							desc: (a, b) => b.price - a.price
						},
						formatter: (item) =>
							Number(item.price).toLocaleString('en', { style: 'currency', currency: item.currency || 'GBP' })
					},
					{
						title: '',
						id: 'actions',
						formatter: (item) => (
							<Link className={styles.actionButton} to={`/inventory/${item.shopId}/items/${barcode}`} title="Edit product">
								<PencilSquareIcon />
							</Link>
						)
					}
				]}
			/>
		</TemplateModal>
	);
};

const OrganisationStockStatusContent = ({ barcode }) => {
	const { data } = useSWR(`organisation-stock-status-${barcode}`, () => getOrganisationStockStatus(barcode), { suspense: true });
	const [showModal, setShowModal] = useState(false);

	return (
		<div className={styles.stockStatus}>
			{data.length ? (
				<>
					<OrganisationStockStatusModal isOpen={showModal} close={() => setShowModal(false)} data={data} barcode={barcode} />
					<button onClick={() => setShowModal(true)}>
						<Pill colour="green">in stock</Pill> in {data.length} locations
						<ChevronRightIcon />
					</button>
				</>
			) : (
				<Pill colour="grey">out of stock</Pill>
			)}
		</div>
	);
};

export default OrganisationStockStatus;
