import React from 'react';
import mixpanel from 'mixpanel-browser';
import { format } from 'date-fns';

import * as Stats from '@components/StatsCard';
import Table from '@components/Table';
import InsightsWrapper, { getCSVData } from '@components/Graphs/DataWrapper';
import AdsRadius from '../AdsRadius';
import { useInsightsDate } from '@services/InsightsDateProvider';

import styles from './RadiusCard.module.scss';

const PopularLocations = ({ data }) => {
	return (
		<Table
			onExpand={(expanded) =>
				mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
					'Table name': 'Local Ads Popular Locations'
				})
			}
			initialLength={6}
			maxLength={40}
			expandable
			data={data}
			defaultSort={{ orderBy: 'clicks', direction: 'desc', handler: (a, b) => b.clicks - a.clicks }}
			fields={[
				{
					id: 'locations',
					title: 'Locations',
					width: '65%',
					formatter: (row) => {
						return row.name;
					}
				},
				{
					id: 'clicks',
					title: 'Clicks',
					width: '15%',
					formatter: (row) => {
						return row.clicks?.toLocaleString();
					},
					sortFn: { asc: (a, b) => a.clicks - b.clicks, desc: (a, b) => b.clicks - a.clicks }
				},
				{
					id: 'views',
					title: 'Views',
					width: '20%',
					formatter: (row) => {
						return row.impressions?.toLocaleString();
					},
					sortFn: {
						asc: (a, b) => a.impressions - b.impressions,
						desc: (a, b) => b.impressions - a.impressions
					}
				}
			]}
		/>
	);
};

const RadiusCard = ({ shop }) => {
	const { startDate, endDate, selectedRange } = useInsightsDate();

	const maxRadius = shop.adCriterions
		? shop.adCriterions.filter((el) => el.type === 'proximity').sort((a, b) => b.radius - a.radius)[0]
		: undefined;

	const query = {
		table: 'local_ads_geo',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shop.id,
		columns: ['clicks', 'impressions', 'name'],
		sum: ['clicks', 'impressions'],
		sort: [{ column: 'impressions', order: 'desc' }]
	};

	return (
		<Stats.Card className={styles.card}>
			<Stats.Title>Where your ads are appearing</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query}>
				{(data) => {
					const csv = getCSVData(data);
					return (
						<>
							<Stats.Download filename="google-popular-locations" csv={csv} />

							<div className={styles.columns}>
								{maxRadius && shop.geolocation && (
									<div className={styles.map}>
										<AdsRadius shop={shop} radius={maxRadius.radius} />
									</div>
								)}
								<PopularLocations data={data} />
							</div>
						</>
					);
				}}
			</InsightsWrapper>
		</Stats.Card>
	);
};

export default RadiusCard;
