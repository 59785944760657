import React from 'react';
import { Source, Layer } from 'react-map-gl';
import circle from '@turf/circle';

import LocationsMap from '../../../components/LocationsMap';

const AdsRadius = ({ shop, radius }) => {
	const location = JSON.parse(shop.geolocation);

	return (
		<LocationsMap shops={[shop]}>
			<Source
				id="radius"
				type="geojson"
				data={{
					type: 'FeatureCollection',
					features: [circle([location.lng, location.lat], radius)]
				}}
			>
				<Layer
					id="radius"
					type="fill"
					source="radius"
					paint={{
						'fill-color': '#50a1ff',
						'fill-opacity': 0.5,
						'fill-antialias': true
					}}
				/>
			</Source>
		</LocationsMap>
	);
};

export default AdsRadius;
