import React from 'react';

import { Protect } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import { ChartPieIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';

const Insights = () => {
	const { shop, data: shops } = useShop();
	const { shopId } = useParams();
	const shopSelected = shopId;
	const onFreemium = shopSelected ? shop.plan === 'freemium' : shops.every((shop) => shop.plan === 'freemium');
	const swisEnabled = shopSelected ? !!shop.swisUrl : shops.some((shop) => !!shop.swisUrl);
	const liaAvailable = shopSelected ? shop.availableChannels?.['local-ads'] : shops.some((shop) => shop.availableChannels?.['local-ads']);
	const productLocatorAvailable = Boolean(
		shopSelected ? shop.availableChannels?.['product-locator'] : shops.some((shop) => shop.availableChannels?.['product-locator'])
	);

	return (
		<Sidebar.Menu>
			<OnboardingAccessControl allowedState={'shop.google.pos-link-ok'}>
				{swisEnabled && (
					<Protect permission="org:insights:read">
						<Sidebar.Item to="/insights/local-listings" activePaths={['/insights']}>
							<ChartPieIcon />
							Insights
						</Sidebar.Item>
						<Sidebar.SubMenu prefix="/insights">
							<Sidebar.SubItem to="/insights/local-listings">Local Listings</Sidebar.SubItem>
							{!onFreemium && liaAvailable && <Sidebar.SubItem to="/insights/local-ads">Local Ads</Sidebar.SubItem>}
							{productLocatorAvailable && <Sidebar.SubItem to="/insights/product-locator">Product Locator</Sidebar.SubItem>}
							<Sidebar.SubItem to="/insights/business-profile">Google Business Profile</Sidebar.SubItem>
						</Sidebar.SubMenu>
					</Protect>
				)}
			</OnboardingAccessControl>
		</Sidebar.Menu>
	);
};

export default Insights;
