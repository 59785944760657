import React from 'react';
import { formatDistanceStrict } from 'date-fns';
import { Stack, Switcher } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import { useShop } from '@services/ShopProvider';
import SwisStatus from '@components/SwisStatus';
import swisPlaceholder from '../../../../assets/images/swis/swis-placeholder.png';
import InsightCardContainer from '../../components/InsightCardContainer';

import styles from './SwisScreenshot.module.scss';
import ActiveProductsCount from '../ActiveProductsCount';

const formatSwisLastSeenAt = (shop) => {
	return shop.swisWidgetLastSeenAt ? `${formatDistanceStrict(new Date(shop.swisWidgetLastSeenAt), new Date())} ago` : undefined;
};

const Active = ({ shop }) => {
	const swisWidgetLastSeenAt = formatSwisLastSeenAt(shop);

	const [, ...key] = shop.swisWidgetS3Path.split('/');

	const imagesUrl = 'https://d1i2wnfnz4jj96.cloudfront.net';

	const url = `${imagesUrl}/${key.join('/')}`;
	return (
		<InsightCardContainer>
			<Stack>
				<Stats.Header>
					<div>
						<Stats.Title>See What&apos;s in Store</Stats.Title>
						{swisWidgetLastSeenAt && <Stats.Subtitle>{`Last checked ${swisWidgetLastSeenAt}`}</Stats.Subtitle>}
					</div>
					<SwisStatus shops={[shop]} />
				</Stats.Header>
				<Switcher>
					<ActiveProductsCount shop={shop} />
				</Switcher>
				<a href={shop.swisUrl} target="_blank" rel="noopener noreferrer">
					<img src={url} className={styles.swisScreenshot} alt="See what's in store screenshot" />
				</a>
			</Stack>
		</InsightCardContainer>
	);
};

const SetUp = () => {
	return (
		<InsightCardContainer>
			<Stack>
				<Stats.Title>See What&apos;s in Store</Stats.Title>
				<img className={styles.swisScreenshot} src={swisPlaceholder} alt="We're setting up your See What's In Store" />
			</Stack>
		</InsightCardContainer>
	);
};

const Teaser = ({ shop }) => {
	const swisWidgetLastSeenAt = formatSwisLastSeenAt(shop);

	return (
		<InsightCardContainer>
			<Stack>
				<Stats.Header>
					<div>
						<Stats.Title>See What&apos;s in Store</Stats.Title>
						{swisWidgetLastSeenAt && <Stats.Subtitle>{`Last checked ${swisWidgetLastSeenAt}`}</Stats.Subtitle>}
					</div>
					<SwisStatus shops={[shop]} />
				</Stats.Header>
			</Stack>
		</InsightCardContainer>
	);
};

const SwisScreenshot = ({ teaser = false }) => {
	const { shop } = useShop();

	if (teaser) return <Teaser shop={shop} />;
	if (shop.swisWidgetLastSeenAt && shop.swisWidgetS3Path) return <Active shop={shop} />;
	else return <SetUp />;
};

export default SwisScreenshot;
