import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Page, Select } from '@nearst/ui';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Card } from '@components/StatsCard';
import { IndustryInsightsTable } from '@components/Insights/IndustryInsights';

import { productCategories } from './utils';
import { sendAnalytics } from '../../../utils/sendAnalytics';
import SimpleWrapper from '../../../components/Graphs/DataWrapper/SimpleWrapper';
import SpinnerCard from '@components/SpinnerCard';

import styles from './IndustryInsights.module.scss';
import { useShop } from '@services/ShopProvider';
import { getStoredQuery } from '@services/insights';

const Loading = () => <SpinnerCard message="Generating report..." />;

const IndustryInsights = () => {
	const { shop, data: shops } = useShop();
	const isSingleShop = useParams().shopId;
	const [selectedCategory, setSelectedCategory] = useState(isSingleShop ? shop.category : shops[0].category);

	const handleChange = (e) => {
		const value = e.target.value;
		sendAnalytics(`Popular Products Category: ${value}`, 'click', shop ? shop.name : shops[0].organisation);
		setSelectedCategory(value);
	};

	return (
		<>
			<Select id="category-selector" value={selectedCategory} onChange={handleChange} className={styles.dropdown}>
				<option disabled selected>
					-- select a category --
				</option>
				{productCategories.sort().map((category) => (
					<option key={category} value={category}>
						{category}
					</option>
				))}
			</Select>

			<div>
				{!selectedCategory ? (
					<p>
						We haven't been able to identify a product category for your store just yet. You can explore the categories from
						across the NearSt network by using the dropdown below.
					</p>
				) : null}
			</div>
			{selectedCategory && (
				<SimpleWrapper
					args={{
						queryId: 'industry-insights',
						shopIds: shop ? [shop.id] : undefined,
						category: selectedCategory
					}}
					fetcher={getStoredQuery}
					LoaderPlaceholder={Loading}
				>
					{(data) => {
						return <IndustryInsightsTable products={data} />;
					}}
				</SimpleWrapper>
			)}
		</>
	);
};

const IndustryInsightsNotAvailable = () => {
	return (
		<Card>
			<h1>Industry Insights Unavailable</h1>
			<p>Because of data availability issues the industry insights are temporarily unavailable.</p>
			<p>The team are working hard to make sure they are back next week.</p>
		</Card>
	);
};

const IndustryInsightsPage = () => {
	return (
		<>
			<Page.Header>
				<Link to="..">
					<Page.BackButton>Reports</Page.BackButton>
				</Link>
				<h1>Industry insights</h1>
				<p>These are the most popular products across categories in the industry in the last 7 days</p>
			</Page.Header>
			<Page.Section>
				<ReactErrorBoundary FallbackComponent={IndustryInsightsNotAvailable}>
					<IndustryInsights />
				</ReactErrorBoundary>
			</Page.Section>
		</>
	);
};

export { IndustryInsightsPage };
