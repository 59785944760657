import React from 'react';
import { Source } from 'react-map-gl';
import { Card } from '@nearst/ui';

import { useStoredQuery } from '@services/insights';
import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';

import Skeleton from '@components/Skeleton';
import * as Stats from '@components/StatsCard';
import LocationsMap from '../../../components/LocationsMap';
import { Circle } from '../../../components/LocationsMap/Pins';
import * as Layers from './Layers';

import styles from './EventLocations.module.scss';

const EventLocations = () => {
	const { data: shops } = useShop();
	const { selectedRange } = useInsightsDate();
	const { data: points, isLoading } = useStoredQuery(
		'product-locator-locations',
		{},
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false,
			refreshInterval: 0
		}
	);

	return (
		<>
			<Card sectioned>
				<Card.Section>
					<Stats.Header>
						<div>
							<Stats.Title>Active customers by location</Stats.Title>
							<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
						</div>
					</Stats.Header>
				</Card.Section>
				{isLoading ? (
					<Skeleton height={500} width={'100%'} />
				) : (
					<div className={styles.mapContainer}>
						<LocationsMap shops={shops}>
							{points && (
								<Source
									id="geo-events"
									type="geojson"
									cluster={true}
									clusterMaxZoom={10}
									clusterMinPoints={1}
									data={{
										type: 'FeatureCollection',
										features: points.map((point) => {
											return {
												type: 'Feature',
												properties: { events: point.total_events },
												geometry: { type: 'Point', coordinates: [point.longitude, point.latitude] }
											};
										})
									}}
								>
									<Layers.ClusterLayer />
									<Layers.ClusterCountLayer />
									<Layers.UnclusteredPointsLayer />
								</Source>
							)}
							<MapKey />
						</LocationsMap>
					</div>
				)}
			</Card>
		</>
	);
};

function MapKey() {
	return (
		<Card className={styles.key} sectioned>
			<ul>
				<li>
					<Circle store />
					{' Your locations'}
				</li>
				<li>
					<Circle className={styles.blue} />
					{' Customers'} <Stats.Tooltip>Accurate to 1000m</Stats.Tooltip>
				</li>
			</ul>
		</Card>
	);
}

export default EventLocations;
