import React from 'react';

import { Page, UItils } from '@nearst/ui';
import DatePicker from '@components/DatePicker';
import FreeLocalListings from '../SWIS/FreeLocalListings';
import ComparisonCard from '../components/ComparisonCard';
import PopularProductsChain from './PopularProductsChain';
import UpgradeRequiredBanner from '@components/UpgradeRequiredBanner';
import InsightTeaserOverlay from '../components/InsightTeaserOverlay';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { getLocalListingsData } from './queries';
import { useShop } from '@services/ShopProvider';

import styles from './LocalListingsChain.module.scss';
import ProductPageLinkingAnalytics from '../SWIS/ProductPageLinkingAnalytics';

const LocalListingsChain = () => {
	const { to, from, fromStartOfWeekStr, toEndOfWeekStr, selectedRange } = useInsightsDate();
	const { data: shops } = useShop();
	const onFreemium = shops.every((shop) => shop.plan === 'freemium');

	return (
		<>
			<Page.Header>
				<h1>Local Listings</h1>
				<p>Understand the trends in your Google organic listings performance and compare metrics across all locations.</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				{onFreemium ? (
					<UpgradeRequiredBanner>
						Upgrade your plan to discover how your products are performing across your Local Listings.
					</UpgradeRequiredBanner>
				) : null}
				<div className={styles.fullWidthCard}>
					{onFreemium ? (
						<InsightTeaserOverlay>
							<div className={UItils.classnames(styles.fullWidthCard, styles.teaser)}>
								<FreeLocalListings teaser subtitle="All locations" />
							</div>
						</InsightTeaserOverlay>
					) : (
						<>
							<FreeLocalListings subtitle="All locations" />
							<ProductPageLinkingAnalytics shops={shops} />
							<ComparisonCard
								subtitle={selectedRange}
								query={(filters) => getLocalListingsData({ to, from, fromStartOfWeekStr, toEndOfWeekStr, ...filters })}
								dimensions={[
									{
										id: 'impressions',
										label: 'Views',
										description: (
											<>
												You are currently comparing the number of times your products were{' '}
												<strong>organically</strong> shown to customers across Google surfaces.
											</>
										)
									},
									{
										id: 'clicks',
										label: 'Clicks',
										description: (
											<>
												You are currently comparing the number of times your products were clicked on across{' '}
												<strong>organic</strong> Google surfaces.
											</>
										)
									},
									{
										id: 'ctr',
										label: 'CTR',
										valueSuffix: '%',
										summaryType: 'avg',
										digits: 2,
										description: (
											<>
												You are currently comparing the average click-through rate of your products across{' '}
												<strong>organic</strong> Google surfaces.
											</>
										)
									}
								]}
							/>
							<PopularProductsChain />
						</>
					)}
				</div>
			</Page.Section>
		</>
	);
};

export default LocalListingsChain;
