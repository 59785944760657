import React, { useRef } from 'react';
import Map, { NavigationControl } from 'react-map-gl';
import mapLib from 'mapbox-gl';

import { Dot } from './Pins';
import { generateInitialBounds } from './lib';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './LocationsMap.module.scss';

/**
 * Renders an interactive map showing one or more shop locations, as well
 * as any custom map layers and content.
 *
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {import('@nearst/shop-data/types').Shop[]} [props.shops]
 * @returns {JSX.Element}
 */
const LocationsMap = ({ children, shops }) => {
	const mapRef = useRef(null);
	const onClick = (event) => {
		const feature = event.features[0];
		if (!feature) return;

		const clusterId = feature.properties.cluster_id;
		const source = mapRef.current.getSource('geo-events');

		source.getClusterExpansionZoom(clusterId, (err, zoom) => {
			if (err) return;

			mapRef.current.easeTo({
				center: feature.geometry.coordinates,
				zoom,
				duration: 500
			});
		});
	};

	return (
		<Map
			className={styles.map}
			mapLib={mapLib}
			initialViewState={{
				bounds: generateInitialBounds(shops)
			}}
			mapStyle="mapbox://styles/devnearst/cm365buc601b901pi1hcv24j9"
			mapboxAccessToken="pk.eyJ1IjoiZGV2bmVhcnN0IiwiYSI6ImNseDRqYXJvOTE2Mm0ydnM0YnQxemEzajYifQ.pCibszFZL8Pi7k7OswvrpA"
			interactiveLayerIds={['clusters']}
			onClick={onClick}
			ref={mapRef}
			scrollZoom={false}
			maxPitch={0}
		>
			{shops?.map((shop) => (
				<Dot key={shop.id} shop={shop} />
			))}
			<NavigationControl showCompass={false} />
			{children}
		</Map>
	);
};

export default LocationsMap;
