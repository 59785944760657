import React from 'react';
import * as Stats from '@components/StatsCard';
import { Loader } from '@nearst/ui';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { useProductFreeListings, useStoredQuery } from '@services/insights';
import { useShop } from '@services/ShopProvider';
import NoData from '@components/Graphs/Placeholders/NoData';
import InsightCardContainer from '../../components/InsightCardContainer';
import PopularProductsTable from '@components/Insights/LocalListings/PopularProductsTable';

const normaliseBarcode = (barcode) => String(barcode).toUpperCase();

const PopularProducts = () => {
	const { shop } = useShop();
	const displayWebsiteVisits = shop.useProductLink;
	const { to, from, selectedRange } = useInsightsDate();
	const { resultSet, isLoading } = useProductFreeListings({ id: shop.id, to, from });
	const { data } = useStoredQuery(
		displayWebsiteVisits ? 'local-listings-website-redirects' : null,
		{ from, to, shopIds: [shop.id] },
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false,
			refreshInterval: 0
		}
	);

	const popularProducts = resultSet?.map((result) => ({
		...result,
		website_redirects: data?.find((row) => normaliseBarcode(row.barcode) === normaliseBarcode(result.barcode))?.website_redirects
	}));

	return (
		<InsightCardContainer>
			<Stats.Title>Popular Products</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<div>
				{isLoading ? (
					<Loader />
				) : !popularProducts?.length ? (
					<NoData />
				) : (
					<PopularProductsTable data={popularProducts} displayWebsiteVisits={displayWebsiteVisits} initialLength={15} />
				)}
			</div>
		</InsightCardContainer>
	);
};

export default PopularProducts;
