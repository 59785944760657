import * as geolib from 'geolib';

export function generateInitialBounds(shops) {
	const shopLocations = shops.map((shop) => ({ ...JSON.parse(shop.geolocation), shop }));
	let bounds;

	if (shopLocations.length >= 2) {
		const center = geolib.getCenterOfBounds(shopLocations);
		const shopDistances = geolib.orderByDistance(center, shopLocations);
		const maxDistance = geolib.getDistance(center, shopDistances[shopDistances.length - 1]);

		// 10% extra padding
		const withPadding = maxDistance + maxDistance * 0.1;
		bounds = geolib.getBoundsOfDistance(
			{
				latitude: Number(center.latitude),
				longitude: Number(center.longitude)
			},
			withPadding
		);
	} else {
		bounds = geolib.getBoundsOfDistance(
			{
				latitude: Number(shopLocations[0].lat),
				longitude: Number(shopLocations[0].lng)
			},
			5000
		);
	}
	const initialBounds = bounds.map(({ latitude, longitude }) => [longitude, latitude]);

	return initialBounds;
}
