import React, { Suspense } from 'react';

import useSWR from 'swr';

import { Card } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';
import NoData from '@components/Graphs/Placeholders/NoData';
import { useStoredQuery } from '@services/insights';
import { batchGetStock } from '@services/stock';

import styles from './RecentProductsCard.module.scss';
import ProductThumbnailPlaceholder from '../../../../../assets/images/insights/product-thumbnail-placeholder.svg';
import { formatTimeAgo } from '../../../utils';

const RecentProductsCardWrapper = () => {
	return (
		<Card>
			<Stats.Title>Recently viewed products</Stats.Title>
			<Suspense
				fallback={
					<>
						<Skeleton height={58} className={styles.skeleton} />
						<Skeleton height={58} className={styles.skeleton} />
						<Skeleton height={58} className={styles.skeleton} />
						<Skeleton height={58} className={styles.skeleton} />
						<Skeleton height={58} className={styles.skeleton} />
					</>
				}
			>
				<RecentProductsCard />
			</Suspense>
		</Card>
	);
};

const RecentProductsCard = () => {
	const { data } = useStoredQuery('product-locator-recent-products', {}, { suspense: true, refreshInterval: 10_000 });

	const barcodes = data.map((item) => item.barcode);
	const { data: stock } = useSWR(barcodes, () => batchGetStock(barcodes), { suspense: true });

	const mergedData = data.map((item) => {
		const stockData = stock.find((stockItem) => stockItem.barcode === item.barcode.toString());
		return { ...stockData, ...item };
	});

	return (
		<>
			{!mergedData.length && (
				<div className={styles.placeholder}>
					<NoData />
				</div>
			)}
			{mergedData.map((item) => (
				<div className={styles.product} key={item.barcode}>
					<img className={styles.productImage} src={item.images?.[0] || ProductThumbnailPlaceholder} alt={item.title} />
					<div className={styles.productContent}>
						{item.title && <div className={styles.productTitle}>{item.title}</div>}
						{item.barcode}
					</div>
					<div>{formatTimeAgo(item.seen_at)}</div>
				</div>
			))}
		</>
	);
};

export default RecentProductsCardWrapper;
