import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getChannels } from '@services/channels';
import { useShop } from '@services/ShopProvider';
import { useStock } from '@services/stock';
import { Card, Stack, StatusIcon, Page } from '@nearst/ui';

import styles from './InventoryItem.module.scss';
import ItemStatus from './ItemStatusCard';

import EditForm from '../components/EditForm';
import ChannelVisibilityCard from './ChannelVisibility';
import DeliveryChannelsCard from './DeliveryChannelsCard';
import StockData from './StockData';

const InventoryItem = () => {
	const { barcode, shopId } = useParams();
	const { shop } = useShop();
	let navigate = useNavigate();
	const { data: stockItem } = useStock(barcode, shopId, { suspense: true });
	const { data: channels } = useSWR(`channels-${shop.id}`, () => getChannels([shop]), { suspense: true });

	const missingFields = [];
	stockItem.issues?.forEach((issue) => {
		if (issue === 'MissingProductTitle') missingFields.push('title');
		else if (issue === 'MissingProductDescription') missingFields.push('description');
		else if (issue === 'MissingProductBrand') missingFields.push('brand');
		else if (issue === 'MissingProductImages') missingFields.push('image');
	});

	useEffect(() => {
		document.title = `Product page - ${stockItem?.title || barcode}`;
	}, [barcode, stockItem?.title]);

	return (
		<>
			<Page.Header>
				<Page.BackButton className={styles.backButton} onClick={() => navigate(-1)}>
					Inventory
				</Page.BackButton>
				<h1>{barcode}</h1>
				<p>Edit product information and configure visibility across enabled channels.</p>
			</Page.Header>
			<Page.Section>
				{missingFields.length > 0 && (
					<Card title="Fix suggestions">
						<p>We have identified some issues that require your attention.</p>
						<p className={styles.warning}>
							<StatusIcon status="error" /> Additional data required: {missingFields.join(', ')}.
						</p>
					</Card>
				)}
				<div className={styles.container}>
					<div className={styles.editCard}>
						<Stack>
							<Card data-cy="inventory-item-form" title="Product data">
								<EditForm key={`${shop.id}_${barcode}`} />
							</Card>
							<DeliveryChannelsCard stockItem={stockItem} shopId={shop.id} />
						</Stack>
					</div>

					<div className={styles.rightStack}>
						<Stack>
							<StockData stockItem={stockItem} />
							<ItemStatus stockItem={stockItem} shopId={shop.id} />
							<ChannelVisibilityCard shopId={shop.id} stockItem={stockItem} channels={channels} />
						</Stack>
					</div>
				</div>
			</Page.Section>
		</>
	);
};

export default InventoryItem;
