import React from 'react';

import { SquaresPlusIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';

const Channels = () => {
	const { shop } = useShop();
	const shopSelected = !!shop?.id;

	if (!shopSelected) return null;

	return (
		<OnboardingAccessControl allowedState="shop.pos.first-upload">
			<Protect permission="org:channels:read">
				<Sidebar.Item to="/channels">
					<SquaresPlusIcon />
					Channels
				</Sidebar.Item>
			</Protect>
		</OnboardingAccessControl>
	);
};

export default Channels;
