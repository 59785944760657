import React from 'react';
import * as Stats from '@components/StatsCard';
import DataWrapper from '@components/Graphs/DataWrapper';
import InsightCardContainer from '../InsightCardContainer';
import { format, subDays } from 'date-fns';
import styles from './ClicksToSales.module.scss';
import Table from '@components/Table';

const addS = (num) => (+num === 1 ? '' : 's');

const ClicksToSales = ({ shop, teaser }) => {
	const query = {
		id: [shop.id],
		table: 'clicks_to_sales',
		sort: [
			{ column: 'sales_date', order: 'desc' },
			{ column: 'sales_from_clicks', order: 'desc' },
			{ column: 'attributed_clicks', order: 'desc' }
		],
		from: format(subDays(new Date(), 31), 'yyyy-MM-dd'),
		to: format(subDays(new Date(), 1), 'yyyy-MM-dd')
	};
	const enabled = shop.totalAdsBudget;
	return (
		<Stats.Column>
			<div className={styles.viewMorePartner}>
				<Stats.Title>
					<span>
						Clicks to sales
						<Stats.Tooltip>These are your products that have been clicked on and sold in-store within 24 hours.</Stats.Tooltip>
					</span>
				</Stats.Title>
				<Stats.Subtitle>Latest suspected sales</Stats.Subtitle>

				{teaser ? null : !enabled ? (
					<div className={styles.noContent}>
						<h3>NearSt Local Ads turned off</h3>
						<p>We can only show you suspected sales if you&rsquo;re using NearSt Local Ads.</p>
					</div>
				) : (
					<DataWrapper query={query}>
						{(data) => {
							if (data.length === 0) {
								return (
									<div className={styles.noContent}>
										<h3>No suspected sales yet</h3>
										<p>We don&rsquo;t have any data to show yet. Please check back here in a few days.</p>
									</div>
								);
							}
							return (
								<>
									<Table
										maxLength={5}
										fields={[
											{
												id: 'products',
												title: 'Product',
												formatter: (row) => {
													return row.product_name;
												}
											},
											{
												id: 'clicks',
												title: 'Clicks',
												formatter: (row) => (
													<>
														{row.attributed_clicks} click{addS(row.attributed_clicks)}
														<br />
														{row.sales_from_clicks} suspected sale
														{addS(row.sales_from_clicks)}
													</>
												)
											}
										]}
										data={data}
									/>
								</>
							);
						}}
					</DataWrapper>
				)}
			</div>
			{enabled ? (
				<div className={styles.viewMoreWrapper}>
					<Stats.ViewMore to={`/insights/${shop.id}/clicks-to-sales`} />
				</div>
			) : null}
		</Stats.Column>
	);
};

const ClicksToSalesInsightCard = ({ shop }) => {
	return (
		<InsightCardContainer>
			<ClicksToSales shop={shop} />
		</InsightCardContainer>
	);
};

export default ClicksToSalesInsightCard;
